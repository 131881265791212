import React from 'react'
import { Footer } from "flowbite-react";
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter ,BsMedium, BsTelegram , BsDiscord} from "react-icons/bs";
import logo from '../../Assets/logo.png'

function Footers() {
  return (
    <div>
         <Footer container className='bg-black dark:bg-black'>
      <div className="w-full">
        <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
            <img
              
              src={logo}
              alt="Flowbite Logo"
              className='w-[170px] h-[130px]'
              
            />

          <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
            <div>
              <Footer.Title title="Links" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">Home</Footer.Link>
                <Footer.Link href="#">About</Footer.Link>
                <Footer.Link href="#">Team</Footer.Link>
                <Footer.Link href="#">Studio</Footer.Link>
                <Footer.Link href="#">Events</Footer.Link>

              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Follow us" />
              <Footer.LinkGroup col>
                <Footer.Link href="https://medium.com/@grandgangstacity" target='_blank'>Medium</Footer.Link>
                <Footer.Link href="https://discord.com/invite/grandgangstacity" target='_blank'>Discord</Footer.Link>
                <Footer.Link href="https://www.instagram.com/grandgangstacity/" target='_blank'>Instagram</Footer.Link>
                <Footer.Link href="https://x.com/grandgangstaci?mx=2" target='_blank'>Twitter</Footer.Link>
                <Footer.Link href="#">Telegram</Footer.Link>

              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright href="#" by="BrandSteps" year={2024} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
          <Footer.Icon href="https://medium.com/@grandgangstacity" icon={BsMedium} className='text-white' />
          <Footer.Icon href="https://discord.com/invite/grandgangstacity" icon={BsDiscord} className='text-white'/>
          <Footer.Icon href="https://www.instagram.com/grandgangstacity/" icon={BsInstagram} className='text-white'/>
            <Footer.Icon href="https://x.com/grandgangstaci?mx=2" icon={BsTwitter} className='text-white'/>
            <Footer.Icon href="#" icon={BsTelegram} className='text-white'/>
          </div>
        </div>
      </div>
    </Footer>
    </div>
  )
}

export default Footers