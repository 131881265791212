// import React from 'react';
import React, { useState, useEffect } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Card } from 'flowbite-react';
import img3 from '../../Assets/ressec.png'
import img4 from '../../Assets/gm.jpeg'
import imgs from '../../Assets/versus.png'

import pi1 from '../../Assets/pi1.png'
import pi2 from '../../Assets/pi2.png'
import pi3 from '../../Assets/pi3.png'
import pi4 from '../../Assets/pi4.png'
import pi5 from '../../Assets/pi5.png'

import ci1 from '../../Assets/ci1.png'
import ci2 from '../../Assets/ci2.png'
import ci3 from '../../Assets/ci3.png'
import ci4 from '../../Assets/ci4.png'
import ci5 from '../../Assets/ci5.png'


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay'; // Swiper's autoplay CSS
import { Autoplay } from 'swiper/modules'; // Import Autoplay from the correct path



const HomeSectionchar = () => {

    const containerStyle = {
        backgroundImage: `url(${pi1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        
        
      };
      const containerStyle2 = {
        backgroundImage: `url(${pi2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        
        
      };
      const containerStyle3 = {
        backgroundImage: `url(${pi3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        
        
      };
      const containerStyle4 = {
        backgroundImage: `url(${pi4})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        
        
      };
      const containerStyle7 = {
        backgroundImage: `url(${pi5})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        
        
      };
      const containerStyle8 = {
        backgroundImage: `url(${ci1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        
        
      };
      const containerStyle9 = {
        backgroundImage: `url(${ci2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        
        
      };
      const containerStyle10 = {
        backgroundImage: `url(${ci3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        
        
      };
      const containerStyle11 = {
        backgroundImage: `url(${ci4})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        
        
      };
      const containerStyle12 = {
        backgroundImage: `url(${ci5})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        
        
      };
      const containerStyle5 = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        width: '100%', // Adjust the width as needed
        
        
      };
      const containerStyle6 = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        width: '370px', // Adjust the width as needed
        
        
      };
      const [isHovered, setIsHovered] = useState(false);
      const [isHovered2, setIsHovered2] = useState(false);


      const divStyle = {
        backgroundImage: `url(${img3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed"

      };


      const [showDetails, setShowDetails] = useState(false);
      const [showDetails2, setShowDetails2] = useState(false);
      const [showDetails3, setShowDetails3] = useState(false);
      const [showDetails4, setShowDetails4] = useState(false);
      const [showDetails5, setShowDetails5] = useState(false);
      const [showDetails6, setShowDetails6] = useState(false);
      const [showDetails7, setShowDetails7] = useState(false);
      const [showDetails8, setShowDetails8] = useState(false);
      const [showDetails9, setShowDetails9] = useState(false);
      const [showDetails10, setShowDetails10] = useState(false);




    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };




  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [mousePosition2, setMousePosition2] = useState({ x: 0, y: 0 });


  const detailsStyle = {
    position: 'absolute',
    backgroundColor: 'black',
    color: 'white',
    padding: '10px',
    borderRadius: '8px',
    width: '250px',
    zIndex: 1000,
    pointerEvents: 'none', // To prevent interfering with mouse interactions
    left: `${mousePosition.x - 70}px`, // Smaller offset to the right of the cursor
    top: `${mousePosition.y - 200}px`,  // Adjust upward to align with cursor
    whiteSpace: 'normal',
  };
  const detailsStyle2 = {
    position: 'absolute', // 'absolute' replaced with 'fixed' for viewport-relative positioning
    top: `${mousePosition2.y}px`,
    left: `${mousePosition2.x}px`,
    backgroundColor: '#000',
    color: '#fff',
    padding: '10px',
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    whiteSpace: 'normal',
    zIndex: 1000,
    width: '250px',
  };


  const handleMouseMove = (event) => {
    // Update mouse position as the mouse moves
    setMousePosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  
  const handleMouseMove2 = (event) => {
    const { clientX, clientY } = event;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
  
    // Adjust positioning for second carousel to avoid going out of bounds
    const boxWidth = 250; // Width of the details box
    const offsetX = 20; // Offset from cursor
  
    let x = clientX - boxWidth - offsetX;
    let y = clientY + 10;
  
    // Keep the box inside the viewport
    if (x < 0) x = clientX + offsetX; // Switch to right side if too close to left edge
    if (y + 150 > viewportHeight) y = viewportHeight - 160; // Adjust if too close to bottom
  
    setMousePosition2({ x, y });
  };
  

    return (
        <div className='sm:h-[100vh] flex flex-col justify-center py-16 ' style={divStyle}>

<div className="flex justify-between sm:mx-56 ">
      <p className="hidden md:flex text-[25px] 2xl:text-[75px] h-fit text-white border-t-[1px] border-b-[1px] 2xl:border-t-[2px] 2xl:border-b-[2px] py-[1px] border-white font-semibold">Gangsta</p>

<p className="text-center text-[22px] text-white mt-24 h-fit  text-center">GGC is a living and breathing ecosystem of players and owners that work in cooperation to achieve their goals</p>
<p className="hidden md:flex text-[25px] 2xl:text-[75px] h-fit text-white border-t-[1px] border-b-[1px] 2xl:border-t-[2px] 2xl:border-b-[2px] py-[1px] border-white font-semibold">Police</p>

</div>




            <div className='block sm:flex lg:px-32'>
                <div className=' w-[370px] 2xl:w-[500px] mx-auto flex justify-center '>
                <Carousel
                swipeable={true}
                arrows={true}
                draggable={true}
                
                responsive={responsive}
                className=''

            >
             <div
  //style={{ position: 'relative', display: 'inline-block', overflow: 'hidden' }}
  onMouseEnter={() => setShowDetails(true)}
  onMouseLeave={() => setShowDetails(false)}
  className="z-[0] flex justify-center"
>
  {/* Main div */}
  <div className='flex justify-center'>
  <div
    style={containerStyle}
    className="text-white w-[300px] h-[450px] 2xl:w-[370px] 2xl:h-[550px] border-2 border-white rounded-xl"
  >
    {/* Conditionally rendering content */}
    {!showDetails ? (
      <></>
    ) : (
      <div className="animate-slideUp bg-[#000000]/80 text-[18px]">
        Only months from retirement, Police Chief Jackson was instrumental in bringing down Grandmaster and his original gang. Now a marked man, he has one choice if he's to make it to retirement age - bring Grandmaster down before he can kill him.
      </div>
    )}
  </div>
  </div>
</div>

     
 <div className='flex justify-center'>
    <div
  style={{ position: 'relative', display: 'inline-block', overflow: 'hidden' }}
  onMouseEnter={() => setShowDetails2(true)}
  onMouseLeave={() => setShowDetails2(false)}
  className="z-[0]"
>
  {/* Main div */}
  <div
    style={containerStyle2}
    className="text-white w-[300px] h-[450px] 2xl:w-[370px] 2xl:h-[550px] border-2 border-white rounded-xl"
  >
    {/* Conditionally rendering content */}
    {!showDetails2 ? (
      <></>
    ) : (
      <div className="animate-slideUp bg-[#000000]/80 text-[18px]">
        While his temper may precede him and preclude him from an office job to instead patrol the streets tackling crime, Mickey was headhunted by Chief Jackson for his ability to think like a criminal (partly because he walks a fine line to becoming one...)
      </div>
    )}
  </div>
</div>
</div>
<div className='flex justify-center'>
    <div
  style={{ position: 'relative', display: 'inline-block', overflow: 'hidden' }}
  onMouseEnter={() => setShowDetails3(true)}
  onMouseLeave={() => setShowDetails3(false)}
  className="z-[0]"
>
  {/* Main div */}
  <div
    style={containerStyle3}
    className="text-white w-[300px] h-[450px] 2xl:w-[370px] 2xl:h-[550px] border-2 border-white rounded-xl"
  >
    {/* Conditionally rendering content */}
    {!showDetails3 ? (
      <></>
    ) : (
      <div className="animate-slideUp bg-[#000000]/80 text-[18px]">
        Captured by Grandmaster's goons while returning from her supermarket shift one evening, Abigail was almost lost in the prostitution underworld. Determined to gain revenge on the men who sold her as a sex slave and make sure it doesn't happen to any other girls, she quickly joined the police force and rose through the ranks to become an Inspector.
      </div>
    )}
  </div>
</div>
</div>

<div className='flex justify-center'>
<div
  style={{ position: 'relative', display: 'inline-block', overflow: 'hidden' }}
  onMouseEnter={() => setShowDetails4(true)}
  onMouseLeave={() => setShowDetails4(false)}
  className="z-[0]"
>
  {/* Main div */}
  <div
    style={containerStyle4}
    className="text-white w-[300px] h-[450px] 2xl:w-[370px] 2xl:h-[550px] border-2 border-white rounded-xl"
  >
    {/* Conditionally rendering content */}
    {!showDetails4 ? (
      <></>
    ) : (
      <div className="animate-slideUp bg-[#000000]/80 text-[18px]">
        When his father was killed by terrorists, Jin Lei swore an oath to the force at 18. Hell-bent on making sure no one else suffers like he and his mother have, Jin Lei, is eager, ambitious, and not too shabby with a weapon either.
      </div>
    )}
  </div>
</div>
</div>
      
<div className='flex justify-center'>   
    <div
  style={{ position: 'relative', display: 'inline-block', overflow: 'hidden' }}
  onMouseEnter={() => setShowDetails5(true)}
  onMouseLeave={() => setShowDetails5(false)}
  className="z-[0]"
>
  {/* Main div */}
  <div
    style={containerStyle7}
    className="text-white w-[300px] h-[450px] 2xl:w-[370px] 2xl:h-[550px] border-2 border-white rounded-xl"
  >
    {/* Conditionally rendering content */}
    {!showDetails5 ? (
      <></>
    ) : (
      <div className="animate-slideUp bg-[#000000]/80 text-[18px]">
        After his own son became a drug addict after being forced to participate in a gang ritual and died of an accidental overdose, Chetan enrolled in the Police Force to avenge his son's death. Broken, angry, divorced, and now with an older daughter dangerously close to following the same path, Chetan will stop at nothing until the streets of Gangsta City are drug-free.
      </div>
    )}
  </div>
</div>
</div>






            </Carousel>
            </div>

            <div style={containerStyle5} className="text-white text-right">
<img src={imgs} alt="" className='h-[410px] w-[500px] 2xl:h-[600px] 2xl:w-[600px]' />
      </div>

            <div className=' w-[370px] 2xl:w-[500px] mx-auto flex justify-center'>
                <Carousel
                swipeable={true}
                arrows={true}
                draggable={true}
                
                responsive={responsive}
                className=''

            >
                
                <div
  //style={{ position: 'relative', display: 'inline-block', overflow: 'hidden' }}
  onMouseEnter={() => setShowDetails6(true)}
  onMouseLeave={() => setShowDetails6(false)}
  className="z-[0]"
>
  {/* Main div */}
  <div className='flex justify-center'>
  <div
    style={containerStyle8}
    className="text-white w-[300px] h-[450px] 2xl:w-[370px] 2xl:h-[550px] border-2 border-white rounded-xl"
  >
    {/* Conditionally rendering content */}
    {!showDetails6 ? (
      <></>
    ) : (
      <div className="animate-slideUp bg-[#000000]/80 text-[18px]">
        In an age-old story of rags to riches, Grandmaster was orphaned at an early age when his parents were killed in a carjacking gone wrong, and the perpetrators were never caught. Learning his first lesson that crime does, in fact, pay, Grandmaster moved around foster families until the age of 15 when he was declared 'a lost boy' by a family on the breaking point and cast into the streets of Gangsta City.
        With nowhere to go, Grandmaster was taken under the wing of a local crime boss, Big Willy Caballero, until he became an even bigger crime boss than his mentor and gunned him down in the street as a statement of his arrival. "It's what Willy would have wanted," he often joked to anyone who stayed alive long enough to hear.
      </div>
    )}
  </div>
  </div>
</div>
<div className='flex justify-center'>
  <div
  style={{ position: 'relative', display: 'inline-block', overflow: 'hidden' }}
  onMouseEnter={() => setShowDetails7(true)}
  onMouseLeave={() => setShowDetails7(false)}
  className="z-[0]"
>
  {/* Main div */}
  <div
    style={containerStyle9}
    className="text-white w-[300px] h-[450px] 2xl:w-[370px] 2xl:h-[550px] border-2 border-white rounded-xl"
  >
    {/* Conditionally rendering content */}
    {!showDetails7 ? (
      <></>
    ) : (
      <div className="animate-slideUp bg-[#000000]/80 text-[18px]">
        Once an esteemed banker with his fingers in all the corporate pies, Sir Kane also knows what it's like to lose everything. Caught siphoning funds from the bank into his own account to fund his wife's cancer treatment, Sir Kane narrowly avoided a prison sentence but now finds himself unemployable with the need to earn more than ever. Tapping into his desperation, Grandmaster puts him to use as the team's money man.
      </div>
    )}
  </div>
</div>
</div>
<div className='flex justify-center'>
    <div
  style={{ position: 'relative', display: 'inline-block', overflow: 'hidden' }}
  onMouseEnter={() => setShowDetails8(true)}
  onMouseLeave={() => setShowDetails8(false)}
  className="z-[0]"
>
  {/* Main div */}
  <div
    style={containerStyle10}
    className="text-white w-[300px] h-[450px] 2xl:w-[370px] 2xl:h-[550px] border-2 border-white rounded-xl"
  >
    {/* Conditionally rendering content */}
    {!showDetails8 ? (
      <></>
    ) : (
      <div className="animate-slideUp bg-[#000000]/80 text-[18px]">
        A longtime dancer at all the seediest Gangsta bars, Diamond Destiny aches to leave the grimy world of dancing and forced prostitution behind and create a new life for her and her young daughter. 
        But, with no real-life skills, she is encouraged by Grandmaster to spend 'just a short' while in The Grand 5 gang to set her and her daughter up for life.
      </div>
    )}
  </div>
</div>
</div>
<div className='flex justify-center'>
    <div
  style={{ position: 'relative', display: 'inline-block', overflow: 'hidden' }}
  onMouseEnter={() => setShowDetails9(true)}
  onMouseLeave={() => setShowDetails9(false)}
  className="z-[0]"
>
  {/* Main div */}
  <div
    style={containerStyle11}
    className="text-white w-[300px] h-[450px] 2xl:w-[370px] 2xl:h-[550px] border-2 border-white rounded-xl"
  >
    {/* Conditionally rendering content */}
    {!showDetails9 ? (
      <></>
    ) : (
      <div className="animate-slideUp bg-[#000000]/80 text-[18px]">
        A spoiled rich kid who lived off his parents his whole life, Max is way over his head with no other way out. One night while showing off his wealth to a group of lovely ladies, he gambled his trust fund and then his family's entire estate at one of Grandmaster's casinos.  
        Unable to pay, Grandmaster has given Max a reprieve from the usual death sentence by recruiting him to be the brains of the new criminal enterprise.
      </div>
    )}
  </div>
</div>
</div>
<div className='flex justify-center'>

    <div
  style={{ position: 'relative', display: 'inline-block', overflow: 'hidden' }}
  onMouseEnter={() => setShowDetails10(true)}
  onMouseLeave={() => setShowDetails10(false)}
  className="z-[0]"
>
  {/* Main div */}
  <div
    style={containerStyle12}
    className="text-white w-[300px] h-[450px] 2xl:w-[370px] 2xl:h-[550px] border-2 border-white rounded-xl"
  >
    {/* Conditionally rendering content */}
    {!showDetails10 ? (
      <></>
    ) : (
      <div className="animate-slideUp bg-[#000000]/80 text-[18px]">
        Benny is a man at rock bottom: a hardened junkie and all-around street thug who literally murdered his own family to feed his crippling meth addiction.
        However, Grandmaster sees the potential in him and a purpose for his murderous meth-driven anger. Benny will walk through walls to get his next fix, and Grandmaster controls him as the gang's 'enforcer' by drip-feeding him just enough meth to keep him proactive.
      </div>
    )}
  </div>
</div>
</div>





            </Carousel>
            </div>
            </div>


      <div style={containerStyle6} className="text-white">

      </div>






        </div>


    );
};


export default HomeSectionchar;

// 
