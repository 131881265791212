import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/homepage/HomePage";
import About from "./Pages/aboutpage/About";
import Privacy from "./Pages/oilicies/Privacy";
import Terms from "./Pages/oilicies/Terms";
import Team from "./Pages/team/Team";
import ComingSoon from "./Components/HomeSections/ComingSoon";

function App() {
  let backendurl = "https://easy-erin-clownfish-ring.cyclic.ap p/"
  let newurl = "https://quaint-pocketbook-bat.cyclic.a pp"
  let vercelurl = "https://list-back-gn1y.vercel.ap p"
  let locals = "http://localhost:800 0"

  return (
    <div>

          <BrowserRouter>
            <Routes>
              <Route path={"/"} element={<HomePage />} />
              <Route path={"/about"} element={<About />} />
              <Route path={"/privacy"} element={<Privacy />} />
              <Route path={"/faqs"} element={<Terms />} />
              <Route path={"/team"} element={<Team />} />
              <Route path={"/comingsoon"} element={<ComingSoon />} />

     
            </Routes>
          </BrowserRouter>
    </div>
  );
}

export default App;
