import logo from '../../Assets/logo.png'
import bglogo from '../../Assets/Header.png'
import recbglogo from '../../Assets/Rectangle.png'
import pngs from '../../Assets/download.png'
import pngss from '../../Assets/hds.png'

import { Button, Navbar } from "flowbite-react";

export function Navbar2() {
  const divStyle = {
    backgroundImage: `url(${bglogo})`,
    backgroundSize: "cover", // This ensures the image covers the entire div
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat", // Prevent the image from repeating
    position: 'relative', // Ensure it's positioned correctly
    zIndex: 50, // Set a higher z-index to bring it to the front
  };

      const divStylea = {
        backgroundImage: `url(${pngss})`,
        backgroundSize: "contain", // Ensure the entire image fits within the div
        backgroundPosition: "center",
      };
  return (
    <>
    <Navbar fluid   className='bg-transparent dark:bg-transparent z-[50] ' style={divStyle}>
      <Navbar.Brand href="/">
        <img src={logo} className="ml-6 h-9 sm:w-[160px] sm:h-[100px] 2xl:w-[205px] 2xl:h-[137px] mb-2 mt-2 " alt=" React Logo" />
      </Navbar.Brand>
      <div className="flex md:order-2">
        <div className='hidden sm:flex px-12'>
      {/*<img src={recbglogo} className=" flex w-[30px] h-[30px] " alt="Flowbite React Logo" /> */}
      </div>
      <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        
        <Navbar.Link href="/about"  className='text-lg 2xl:text-[24px] text-white dark:text-white mt-1 2xl:mt-2'>About Us</Navbar.Link>
        <Navbar.Link href="/" className='text-3xl 2xl:text-[55px] text-white dark:text-white font-bold tracking-wider '>
          Play
        </Navbar.Link>
        <Navbar.Link href="/team"  className='text-lg 2xl:text-[24px] text-white dark:text-white mt-1 2xl:mt-2'>Team</Navbar.Link>


      </Navbar.Collapse>
    </Navbar>

    </>
  );
}
