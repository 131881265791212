import React from 'react'
import team1 from '../../Assets/team1.png'
import team2 from '../../Assets/team2.png'
import team3 from '../../Assets/team3.png'
import team4 from '../../Assets/team4.png'
import team5 from '../../Assets/team5.png'
import { Navbar2 } from '../../Components/Header/Navbar2'
import Footers2 from '../../Components/Footer/Footer2'

function Team() {
  return (
    <div className='bg-black text-white montserrat-text'>
    <Navbar2/>
  
<div className="montserrat-text">
  <div className='px-6 py-12 flex justify-center'>
<p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-semibold uppercase w-fit">TEAM</p>
</div>

<div className=' pb-16'>
<div className="flex lg:hidden flex-wrap justify-center gap-3">
    <img src={team1} alt="team1" className='w-[250px] h-[350px]' />
    <img src={team2} alt="team2" className='w-[250px] h-[350px]' />
    <img src={team5} alt="team3" className='w-[250px] h-[350px]' />
    <img src={team4} alt="team4" className='w-[250px] h-[350px]' />
    <img src={team3} alt="team5" className='w-[250px] h-[350px]' />
    <img src={team2} alt="team2" className='w-[250px] h-[350px] ' />
    <img src={team5} alt="team3" className='w-[250px] h-[350px] ' />
    <img src={team4} alt="team4" className='w-[250px] h-[350px] ' />
    <img src={team3} alt="team5" className='w-[250px] h-[350px] ' />
    <img src={team2} alt="team2" className='w-[250px] h-[350px] ' />
    <img src={team4} alt="team4" className='w-[250px] h-[350px] ' />
    <img src={team3} alt="team5" className='w-[250px] h-[350px] ' />
</div>

<div className="hidden lg:flex flex-wrap justify-center gap-3">
    <img src={team1} alt="team1" className='w-[250px] h-[350px]' />
    <img src={team2} alt="team2" className='w-[250px] h-[350px]' />
    <img src={team5} alt="team3" className='w-[250px] h-[350px]' />
    <img src={team4} alt="team4" className='w-[250px] h-[350px]' />
    <img src={team3} alt="team5" className='w-[250px] h-[350px]' />


</div>
<div className=" hidden lg:flex flex-wrap mt-8 justify-center gap-3">
    <img src={team2} alt="team2" className='w-[250px] h-[350px] ' />
    <img src={team5} alt="team3" className='w-[250px] h-[350px] ' />
    <img src={team4} alt="team4" className='w-[250px] h-[350px] ' />
    <img src={team3} alt="team5" className='w-[250px] h-[350px] ' />


</div>

<div className=" hidden lg:flex flex-wrap mt-8 justify-center gap-3">
    <img src={team2} alt="team2" className='w-[250px] h-[350px] ' />
    <img src={team4} alt="team4" className='w-[250px] h-[350px] ' />
    <img src={team3} alt="team5" className='w-[250px] h-[350px] ' />


</div>
    </div>
</div>
<Footers2/>

</div>
  )
}

export default Team