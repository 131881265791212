import React from "react"
import eventhead from '../../Assets/img5.jpg'
import eventhead2 from '../../Assets/Quest.png'
import eventhead3 from '../../Assets/RedBtnBG 1.png'
import img1 from '../../Assets/bg2item.png'
import img2 from '../../Assets/bg3item.png'
import img3 from '../../Assets/bg4item.png'
import bgimg1 from '../../Assets/p1.png'
import bgimg2 from '../../Assets/p2.png'
import bgimg3 from '../../Assets/p3.png'
import bgimg4 from '../../Assets/p4.png'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: `80vw`,
  bgcolor: 'black',
  border: '2px solid #000',
  boxShadow: 24,
  height: '80vh',
  p: 4,
  color: 'white'
};

const HeroSection6 = () => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

    const divStyle = {
        backgroundImage: `url(${eventhead})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed"

      };
      const divStyle2 = {
        backgroundImage: `url(${eventhead3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

      };
    
      const containerStyle = {
        backgroundImage: `url(${eventhead2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        
        
      };
      const containerStyle2 = {
        backgroundImage: `url(${img1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        padding: '10px',
        
        
      };
      const containerStyle3 = {
        backgroundImage: `url(${img2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        
        
      };
      const containerStyle4 = {
        backgroundImage: `url(${img3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        padding: '10px',
        
        
      };
  return (
    <>
    <div style={divStyle} className="px-1 py-24 2xl:h-[100vh] flex flex-col justify-center">
      <div className="pb-16  flex justify-center">
    <p className="text-[25px] 2xl:text-[55px] border-t-[1px] border-b-[1px] py-[1px] border-white text-white font-bold uppercase">GamePlay Overview</p>
    </div>
    <div className='flex  justify-center  flex-wrap gap-12 2xl:gap-16' >
        <div className="w-[290px] 2xl:w-[370px] 2xl:h-[634px]">
    <div style={containerStyle} className=" w-[290px] 2xl:w-[370px] text-white h-[500px]">
        <p className="text-[25px] 2xl:text-[40px] 2xl:leading-9 border-t-[1px] border-b-[1px] py-[1px] border-white font-bold">TIME <br/> TRIAL</p>
      </div>
      <button className="w-full  py-4 mt-6 text-white" onClick={handleOpen} style={divStyle2}>
        <p className="text-center text-[19px]" >EXPLORE</p></button>
      </div>
      <div className="w-[290px] 2xl:w-[370px] 2xl:h-[634px]">
    <div style={containerStyle2} className=" w-[290px] 2xl:w-[370px] text-white h-[500px]">
        <p className="text-[25px] 2xl:text-[40px] 2xl:leading-9 border-t-[1px] border-b-[1px] py-[1px] border-white font-bold">TEAM <br />DEATH MATCH</p>
      </div>
      <button className="w-full  py-4 mt-6 text-white" onClick={handleOpen2} style={divStyle2}>
        <p className="text-center text-[19px]" >EXPLORE</p></button>
      </div>
      <div className="w-[290px] 2xl:w-[370px] 2xl:h-[634px]">
    <div style={containerStyle3} className=" w-[290px] 2xl:w-[370px] text-white h-[500px]">
        <p className="text-[25px] 2xl:text-[40px] 2xl:leading-9 border-t-[1px] border-b-[1px] py-[1px] border-white font-bold">BATTLE <br />ROYALE</p>
      </div>
      <button className="w-full  py-4 mt-6 text-white" onClick={handleOpen3} style={divStyle2}>
        <p className="text-center text-[19px]">EXPLORE</p></button>
      </div>
      <div className="w-[290px] 2xl:w-[370px] 2xl:h-[634px]">
    <div style={containerStyle4} className=" w-[290px] 2xl:w-[370px] text-white h-[500px]">
        <p className="text-[25px] 2xl:text-[40px] 2xl:leading-9 border-t-[1px] border-b-[1px] py-[1px] border-white font-bold">OPEN <br /> WORLD QUEST</p>
      </div>
      <button className="w-full  py-4 mt-6 text-white" onClick={handleOpen4} style={divStyle2}>
        <p className="text-center text-[19px]">EXPLORE</p></button>
      </div>
      
    </div>

    </div>


    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

           <div className="block md:flex justify-center gap-6 items-center sm:mt-12">
            <img src={bgimg2} alt="img" className="h-[250px] w-[250px] sm:h-[360px] sm:w-[550px] 2xl:h-[496px] 2xl:w-[891px]" />
            <div className="flex flex-col gap-10">
              <div className=" flex justify-center">
            <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-bold 2xl:border-t-[2px] 2xl:border-b-[2px] 2xl:text-[40px]">TIME TRIAL</p>
            </div>
            <p className="text-center pr-8 2xl:text-[36px] font-normal">
           Team Death match contains 5 v 5 team match, with upto 3 maps and 10 minutes of gameplay. Custom Lobbies & Public Lobbies are available to play.
           </p>
           </div>
           </div>

        </Box>
      </Modal>
      
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

           <div className="block md:flex justify-center gap-6 items-center sm:mt-12">
            <img src={bgimg3} alt="img" className="h-[250px] w-[250px] sm:h-[360px] sm:w-[550px] 2xl:h-[496px] 2xl:w-[891px]" />
            <div className="flex flex-col gap-10">
              <div className=" flex justify-center">
            <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-bold 2xl:border-t-[2px] 2xl:border-b-[2px] 2xl:text-[40px]">TEAM DEATH MATCH</p>
            </div>
            <p className="text-center pr-8 2xl:text-[36px] font-normal">
           Team Death match contains 5 v 5 team match, with upto 3 maps and 10 minutes of gameplay. Custom Lobbies & Public Lobbies are available to play.
           </p>
           </div>
           </div>

        </Box>
      </Modal>
      <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

           <div className="block md:flex justify-center gap-6 items-center sm:mt-12">
            <img src={bgimg1} alt="img" className="h-[250px] w-[250px] sm:h-[360px] sm:w-[550px] 2xl:h-[496px] 2xl:w-[891px]" />
            <div className="flex flex-col gap-10">
              <div className=" flex justify-center">
            <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-bold 2xl:border-t-[2px] 2xl:border-b-[2px] 2xl:text-[40px]">BATTLE ROYALE</p>
            </div>
            <p className="text-center pr-8 2xl:text-[36px] font-normal">
           Team Death match contains 5 v 5 team match, with upto 3 maps and 10 minutes of gameplay. Custom Lobbies & Public Lobbies are available to play.
           </p>
           </div>
           </div>

        </Box>
      </Modal>
      <Modal
        open={open4}
        onClose={handleClose4}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

           <div className="block md:flex justify-center gap-6 items-center sm:mt-12">
            <img src={bgimg4} alt="img" className="h-[250px] w-[250px] sm:h-[360px] sm:w-[550px] 2xl:h-[496px] 2xl:w-[891px]" />
            <div className="flex flex-col gap-10">
              <div className=" flex justify-center">
            <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-bold 2xl:border-t-[2px] 2xl:border-b-[2px] 2xl:text-[40px]">BATTLE ROYALE QUEST</p>
            </div>
            <p className="text-center pr-8 2xl:text-[36px] font-normal">
           Team Death match contains 5 v 5 team match, with upto 3 maps and 10 minutes of gameplay. Custom Lobbies & Public Lobbies are available to play.
           </p>
           </div>
           </div>

        </Box>
      </Modal>
    </>
  )
}

export default HeroSection6;