// src/ComingSoon.js
import React from 'react';
import { Link } from 'react-router-dom';

const ComingSoon = () => {
  return (
    <div className="flex items-center justify-center h-screen text-white">
      <div className="bg-black bg-opacity-60 p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-6xl font-bold mb-4">Coming Soon</h1>
        <Link to={`/`}> <p className="mb-6">Meanwhile Learn More Abour Us Here</p>
        </Link>
      </div>
    </div>
  );
};

export default ComingSoon;
