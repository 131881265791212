import React from 'react'
import { Footer } from "flowbite-react";
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter ,BsMedium, BsTelegram , BsDiscord} from "react-icons/bs";
import logo from '../../Assets/logo.png'
import { FaRedditAlien } from "react-icons/fa6";
import { FaMedium } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";

import footerbanner from '../../Assets/footerbanner.png'


function Footers2() {
  const divStyle = {
    backgroundImage: `url(${footerbanner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

  };

  return (
    <>
    <div className=' py-16 -mt-8 flex justify-center ' style={divStyle}>
         <div className='flex flex-wrap 2xl:max-w-[1600px] w-[1600px] justify-between gap-8 px-2 2xl:px-0 2xl:gap-12 items-center'>
          <div>
            <img src={logo} alt="logo" className='w-[238px] h-[155px] sm:w-[150px] sm:h-[100px]' />
          </div>

          <div className='flex flex-col justify-center gap-4 text-white lg:border-l-[1px] lg:pl-16 lg:pr-24 border-[#181818]'>
            <p className='font-extrabold text-[22px]'>
            Connect with GANGSTA
            </p>
            <div className='flex gap-9'>
            <Link to={`https://t.me/Grandgangstacity`} target='_blank'>
          <FaTelegramPlane className='text-white text-[25px]'/>
          </Link>
          <Link to={`https://discord.gg/grandgangstacity`} target='_blank'>
          <FaDiscord className='text-white text-[22px] 2xl:text-[25px]'/>
          </Link>
          <Link to={`https://twitter.com/grandgangstaci`} target='_blank'>
          <FaXTwitter className='text-white text-[22px] 2xl:text-[25px]'/>
          </Link>
          <Link to={`https://www.youtube.com/@grandgangstacity?si=af2nychVQEcxukjo`} target='_blank'>
          <IoLogoYoutube className='text-white text-[22px] 2xl:text-[25px]'/>
          </Link>
          <Link to={`https://www.linkedin.com/company/grandgangstacity/`} target='_blank'>
          <FaLinkedinIn className='text-white text-[22px] 2xl:text-[25px]'/>
          </Link>
            </div>

            <div className='flex gap-9'>
            <Link to={`https://www.instagram.com/grandgangstacity/`} target='_blank'>
          <FaInstagram className='text-white text-[22px] 2xl:text-[25px]'/>
          </Link>
          <Link to={`https://www.reddit.com/user/GrandGangsta_City`} target='_blank'>
          <FaRedditAlien className='text-white text-[22px] 2xl:text-[25px]'/>
          </Link>
          <Link to={`https://www.facebook.com/grandgangstacity?mibextid=LQQJ4d`} target='_blank'>
          <FaFacebookF className='text-white text-[22px] 2xl:text-[25px]'/>
          </Link>
          <Link to={`https://medium.com/@grandgangstacity`} target='_blank'>
          <FaMedium className='text-white text-[22px] 2xl:text-[25px]'/>
          </Link>
          <Link to={`https://www.tiktok.com/@grandgangstacity`} target='_blank'>
          <FaTiktok className='text-white text-[22px] 2xl:text-[25px]'/>
          </Link>
            </div>
          </div>

          <div className='flex flex-col justify-center gap-2 text-white' >
            <Link to='/comingsoon'><p className='font-light text-[14px] 2xl:text-[20px]'>TON Mini Game</p></Link>
            <Link to='https://grandgangstacity.com/whitepaper.html' target="_blank"><p className='font-light text-[14px] 2xl:text-[20px]'>Whitepaper</p></Link>
            <Link to='/comingsoon'><p className='font-light text-[14px] 2xl:text-[20px]'>Tokenomics</p></Link>
            <Link to='#partnerships'><p className='font-light text-[14px] 2xl:text-[20px]'>Partnerships</p></Link>

          </div>
          <div className='flex flex-col justify-center gap-2 text-white lg:border-l-[1px] lg:pl-12 border-[#181818]' >
            <Link to='/about'><p className='font-light text-[14px] 2xl:text-[20px]'>About Us</p></Link>
            <Link to='/team'><p className='font-light text-[14px] 2xl:text-[20px]'>Our Team</p></Link>
            <Link to='/privacy'><p className='font-light text-[14px] 2xl:text-[20px]'>Privacy Policy</p></Link>
            <Link to='/faqs'><p className='font-light text-[14px] 2xl:text-[20px]'>FAQ</p></Link>

          </div>

          <form action="https://api.web3forms.com/submit" method="POST" className='flex flex-col gap-3' >
          <input type="hidden" name="access_key" value="b5e7501a-ce04-4d45-b901-5d306319b733"></input>
            <input type="text" placeholder='Enter Your E-mail' name='email' id='email' className="rounded-full text-black text-[14px] 2xl:text-[18px] px-2 py-1" />
            <button className='w-[100%] bg-[#BA1010] rounded-full text-white text-[14px] py-1 font-bold'>Subscribe</button>

          </form>
         </div>
    </div>

    <div className='flex justify-center bg-[#BA1010] py-2 text-[14px] text-white gap-1'>© 2024  <Link to='https://brandsteps.com.pk/' target='_blank'> BrandSteps</Link> All Rights Reserved.</div>
    </>
  )
}

export default Footers2