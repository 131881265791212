import React from "react"
import eventhead from '../../../Assets/bgg.jpg'
import pngs from '../../../Assets/btnbg.png'
import { Link } from "react-router-dom";

const HeroSection1 = () => {
  const divStyle = {
    backgroundImage: `url(${eventhead})`,
    backgroundSize: "cover",
    backgroundPosition: "50%",
    position: 'relative', // Ensure it's positioned correctly
    zIndex: 0, // Lower z-index to keep it behind
  };
      const divStyle2 = {
        backgroundImage: `url(${pngs})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

      };
    
      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        width: '400px', // Adjust the width as needed
        paddingBottom: '100px'
      };
    
  return (
    <div>
    <div style={divStyle} className="py-12  sm:h-[100vh] 2xl:h-[79vh] mt-[-10px] flex flex-col justify-center">
    <div className='flex   justify-end flex-wrap pt-20 pb-16 md:pr-48 md:gap-20' >
    <div style={containerStyle} className="text-white text-right">

      </div>
      <div style={containerStyle} className="text-white text-right ">
        <p className="text-6xl 2xl:text-[100px] font-normal opacity-100 text-right "><span className="text-[30px] 2xl:text-[60px] text-white font-normal">WELCOME </span>
        <br/><span className="font-normal tracking-widest text-[18px] 2xl:text-[25px] mr-[-15px]">TO</span><span className="font-extrabold "> GRAND <br/>GANGSTA <br/></span> 
        <p className=" font-bold tracking-[3rem] text-[50px] mt-[-6px] mr-[-30px]">CITY</p></p>

      </div>
      
    </div>

    <div className="flex justify-center gap-2 sm:gap-8">
    <Link to='https://grandgangstacity.com/whitepaper.html' target="_blank">  <button className="px-1 sm:px-8 h-[60px] bg-red-600 text-white text-[13px] sm:text-[23px] hover:bg-black" >WHITE PAPER</button></Link>
    <Link to='/comingsoon' target="_blank"><button className="px-1 sm:px-8 h-[60px] text-white text-[13px] sm:text-[23px] bg-red-600  hover:bg-black" >TOKENOMICS</button>
     </Link>
     <Link to='https://youtu.be/wroyzPcK62E?si=eZoKfnG9ly1k6Rgq' target="_blank"> <button className="px-1 sm:px-8 h-[60px] text-white text-[13px] sm:text-[23px] bg-red-600 hover:bg-black" >WATCH TRAILER</button>
     </Link>

    </div>
    </div>
    </div>
  )
}

export default HeroSection1;